import React from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography'

const styles = {
};

const Title = ({ children, classes, level='h2', style = {}, id }) => {
    const marginTop = level === 'h2' ? '2rem' : '1.3rem'
    const marginBottom = '0.6rem'
    const styleToUse = {marginTop, marginBottom, ...style}
    return (
        <Typography variant={level} paragraph color={level === 'h2' ? 'primary' : 'inherit'} classes={classes}
                    style={styleToUse} id={id}>{children}</Typography>
    )
}

export default withStyles(styles)(Title);
