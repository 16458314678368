import React from "react";
import Page from "../../components/Page";
import Title from '../../components/Title'
import Para from '../../components/Para'
import withRoot from '../../utils/withRoot'

const Cookies
    = props => {
    return (
        <Page title="Cookie Policy">

            <Title>About cookies</Title>

            <Para>This website uses cookies.  By using this website and agreeing to this
                policy, you consent to Ashridge Technologies's use of cookies in accordance with the
                terms of this policy.</Para>

            <Para>Cookies are files sent by web servers to web browsers, and stored by the web
                browsers.
            </Para>

            <Para>The information is then sent back to the server each time the browser
                requests a page from the server.  This enables a web server to
                identify and track web browsers.
            </Para>

            <Para>There are two main kinds of cookies: session cookies and persistent
                cookies.  Session cookies are deleted from your computer when you
                close your browser, whereas persistent cookies remain stored on your
                computer until deleted, or until they reach their expiry date.</Para>

            <Title>Cookies on our website</Title>

            <Para>Ashridge Technologies uses cookies on this website, for the following
                purposes:</Para>

            <Title>Google cookies</Title>

            <Para>Ashridge Technologies uses Google Analytics to analyse the use of this website.  Google
                Analytics generates statistical and other information about website
                use by means of cookies, which are stored on users' computers.  The
                information generated relating to our website is used to create
                reports about the use of the website. Google will store and use this
                information.  Google's privacy policy is available at:
                http://www.google.com/privacypolicy.html.</Para>


            <Title>Refusing cookies</Title>

            <Para>Most browsers allow you to refuse to accept cookies.  You may find <a href="http://www.wikihow.com/Disable-Cookies">this article</a> helpful.
            </Para>

            <Para>Blocking cookies will have a negative impact upon the usability of some websites.</Para>

        </Page>
    )
};

export default withRoot(Cookies);
