import React from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography'

const styles = {
};

const Para = ({ children, classes, variant = 'body1', align = 'left', style = {} }) => (
    <Typography variant={variant} paragraph align={align} classes={classes} style={style}>{children}</Typography>
);

export default withStyles(styles)(Para);
